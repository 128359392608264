import React, { useState, useEffect } from 'react';

import { DownArrowIcon, UpArrowIcon, } from '../../components/Icons';
import Tooltip from '../../components/Elements/Tooltip';

import requestClient from '../../utils/requestClient';
import { pillarNameMapper } from '../../utils/pillarMapper';
import Histogram from '../../components/Elements/Reporting/Histogram';
import Loader from '../../components/Elements/Loader';
import WellbeingChart from '../../components/Elements/Reporting/WellBeingChart';
import NewsletterOpensChart from '../../components/Elements/Reporting/NewsletterOpensChart';
import NewsletterClicksChart from '../../components/Elements/Reporting/NewsletterClicksChart';
import RadarChart from '../../components/Elements/Reporting/RadarChart';


const Overview = ({ }) => {
    const [data, setData] = useState(null);
    const [company, setCompany] = useState(null);

    useEffect(() => {
        async function getData() {
            const response = await requestClient().get('/api/v2/employer/dashboard/')

            setData(response.data.data);
        }
        getData();
    }, []);

    useEffect(() => {
        async function getCompany() {
            const response = await requestClient().get('/api/v2/employer/company/')

            setCompany(response.data.data);
        }
        getCompany();
    }, []);

    return (data ?
        <main>
            <section>
                <div className='container py-8 grid grid-cols-12 gap-8'>
                    <div className='col-span-12'>
                        <div className="mb-4">
                            <h1>Wellbeing Snapshot</h1>
                            <div className='prose'>
                                <p>Here is a snapshot of the wellbeing across your organisation.</p>
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-8">
                            <div className="col-span-12 bg-white p-8 lg:col-span-6">
                                <div className='mb-4 flex items-center gap-2'>
                                    <h2 className='h4'>Best Performing Pillars</h2>
                                    <Tooltip text="Track how you are performing against the pilars of Mind, Body, Career, Surrounding, Relationships and Money." />
                                </div>
                                <Histogram histogramData={[data.top_pillar_1, data.top_pillar_2]} />
                            </div>
                            <div className="col-span-12 bg-white p-8 lg:col-span-6">
                                <div className='mb-4 flex items-center gap-2'>
                                    <h2 className='h4'>Lowest Performing Pillars</h2>
                                    <Tooltip text="Track how you are performing against the pilars of Mind, Body, Career, Surrounding, Relationships and Money." />
                                </div>
                                <Histogram histogramData={[data.bottom_pillar_1, data.bottom_pillar_2]} />
                            </div>
                        </div>
                    </div>

                    <div className='col-span-12'>
                        <div className="mb-4">
                            <h2 className='mb-1'>Trends</h2>
                            <div className='prose'>
                                <p>Explore your month-on-month and quarterly wellbeing trends to see how each pillar is evolving over time. This insight helps track progress and identify areas for focused improvement.</p>
                            </div>
                        </div>
                        <div className="grid gap-2 lg:grid-cols-2">
                            {data.quarterly_change.map((item, index) => (
                                <div key={index} className="bg-white flex items-center p-8 gap-4 lg:gap-8 text-center flex-col lg:flex-row lg:text-left">
                                    {pillarNameMapper(item.name).icon('max-h-[50px]')}
                                    <h3 className='h4'>{pillarNameMapper(item.name).title}</h3>
                                    <div className='flex gap-6 lg:ml-auto'>
                                        <div>
                                            <span className={`flex items-baseline ${data.monthly_change[index].change > 0 ? 'text-green' : 'text-red'}`}>
                                                {data.monthly_change[index].change > 0 ? <UpArrowIcon /> : null}
                                                {data.monthly_change[index].change < 0 ? <DownArrowIcon /> : null}
                                                <span className='leading-none text-[35px]'>{data.monthly_change[index].change}%</span>
                                            </span>
                                            <span className="uppercase text-center block text-xs">Monthly</span>
                                        </div>
                                        <div>
                                            <span className={`flex items-baseline ${data.quarterly_change[index].change > 0 ? 'text-green' : 'text-red'}`}>
                                                {data.quarterly_change[index].change > 0 ? <UpArrowIcon /> : null}
                                                {data.quarterly_change[index].change < 0 ? <DownArrowIcon /> : null}
                                                <span className='leading-none text-[35px]'>{data.quarterly_change[index].change}%</span>
                                            </span>
                                            <span className="uppercase text-center block text-xs">Quarterly</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="col-span-12 lg:col-span-4">
                        <div className="mb-4">
                            <h3>Engagement</h3>
                        </div>
                        <div className="grid gap-2">
                            <div className='bg-white p-4 text-blue flex justify-between items-center'>
                                <span className="h4 text-blue">Logins this month</span>
                                <span className={`text-[45px]`}>{data.users}</span>
                            </div>
                            <div className='bg-white p-4 text-green flex justify-between items-center'>
                                <span className="h4 text-green">Accounts Activated</span>
                                <span className={`text-[45px]`}>{company.accepted_invites_percent}%</span>
                            </div>
                        </div>
                    </div>

                    {'newsletter' in data && data.newsletter? (<div className="col-span-12 lg:col-span-4">
                        <div className="mb-4">
                            <h3>Newsletter Open Rates</h3>
                        </div>
                        <div className="bg-white p-8">
                            <NewsletterOpensChart data={data.newsletter} />
                        </div>
                    </div>) : null}

                    {'newsletter' in data && data.newsletter ? (<div className="col-span-12 lg:col-span-4">
                        <div className="mb-4">
                            <h3>Newsletter Clicks Rates</h3>
                        </div>
                        <div className="bg-white p-8">
                            <NewsletterClicksChart data={data.newsletter} />
                        </div>
                    </div>) : null}

                    {data.top_positive_sentiment_words.length > 0 ? (<div className="col-span-12 lg:col-span-6">
                        <div className="mb-4">
                            <h3>Sentiment Analysis (+ve)</h3>
                        </div>
                        <div className="bg-white p-8">
                            <RadarChart title="Postive Sentiments" colour="44,154,201" data={data.top_positive_sentiment_words} />
                        </div>
                    </div>) : null}

                    {data.top_negative_sentiment_words.length > 0 ? (<div className="col-span-12 lg:col-span-6">
                        <div className="mb-4">
                            <h3>Sentiment Analysis (-ve)</h3>
                        </div>
                        <div className="bg-white p-8">
                            <RadarChart title="Negative Sentiments" colour="236,74,24" data={data.top_negative_sentiment_words} />
                        </div>
                    </div>) : null}

                    <div className="col-span-12">
                        <div className="mb-4">
                            <h3>In Detail</h3>
                        </div>
                        <div className="bg-white p-8">
                            <WellbeingChart data={data.wellbeing_data} months={data.data.months} />
                        </div>
                    </div>
                </div>
            </section>
        </main>
        : <Loader className="my-8" text="Loading your dashboard" />);
};

export default Overview;
